<template>
  <div class="page-container">
    <div class="page-body">
      <p class="paper-tips">提示：全文投稿截止时间为2024年8月31日，请您核对论文信息并上传论文全文稿件。</p>
      <el-form ref="form" :model="form" label-position="left" label-width="90px" label-suffix=":" :rules="rules">
        <el-form-item label="论文全文" prop="bodyDocUrl">
          <el-input v-model="form.bodyDocUrl" placeholder="请点击右侧上传文件" disabled clearable>
            <template slot="append">
              <el-upload :action="`${baseURL}/system/thesis/uploadFile`" :show-file-list="false"
                :before-upload="(file) => handleBeforeUpload(file, 20 * 1024 * 1024)"
                :on-success="(response) => handleUploadSuccess(response, 'bodyDocUrl')" accept=".doc, .docx, .pdf">
                <el-button type="primary" icon="el-icon-upload2">上传文件</el-button>
              </el-upload>
            </template>
          </el-input>
          <p class="tips">仅允许上传doc、docx 、pdf格式文件，文件大小不超过20M。</p>
        </el-form-item>

        <el-form-item label="保密证明" prop="secrecyDocUrl">
          <el-input v-model="form.secrecyDocUrl" placeholder="请点击右侧上传文件" disabled clearable>
            <template slot="append">
              <el-upload :action="`${baseURL}/system/thesis/uploadFile`" :show-file-list="false"
                :before-upload="(file) => handleBeforeUpload(file, 20 * 1024 * 1024)"
                :on-success="(response) => handleUploadSuccess(response, 'secrecyDocUrl')" accept=".doc, .docx, .pdf">
                <el-button type="primary" icon="el-icon-upload2">上传文件</el-button>
              </el-upload>
            </template>
          </el-input>
          <p class="tips">仅允许上传doc、docx 、pdf格式文件，文件大小不超过20M。</p>
        </el-form-item>

        <el-form-item label="版权协议" prop="copyrightDocUrl">
          <el-input v-model="form.copyrightDocUrl" placeholder="请点击右侧上传文件" disabled clearable>
            <template slot="append">
              <el-upload :action="`${baseURL}/system/thesis/uploadFile`" :show-file-list="false"
                :before-upload="(file) => handleBeforeUpload(file, 20 * 1024 * 1024)"
                :on-success="(response) => handleUploadSuccess(response, 'copyrightDocUrl')" accept=".doc, .docx, .pdf">
                <el-button type="primary" icon="el-icon-upload2">上传文件</el-button>
              </el-upload>
            </template>
          </el-input>
          <p class="tips">仅允许上传doc、docx 、pdf格式文件，文件大小不超过20M。</p>
        </el-form-item>
      </el-form>
      <el-descriptions :column="1">
        <el-descriptions-item label="论文题目">{{ thesisInfo.thesisTopic }}</el-descriptions-item>
        <el-descriptions-item label="关键词">{{ thesisInfo.thesisKeys }}</el-descriptions-item>
        <el-descriptions-item label="摘要">{{ thesisInfo.thesisAbs }}</el-descriptions-item>
        <el-descriptions-item label="所投专题">{{ thesisInfo.subject }}</el-descriptions-item>
        <el-descriptions-item label="稿件">
          <template v-if="thesisInfo.absDocUrl || thesisInfo.secrecyDocUrl || thesisInfo.copyrightDocUrl">
            <div v-if="thesisInfo.absDocUrl"><a
                :href="`${baseURL}/system/thesis/downloadFile?key=${thesisInfo.absDocUrl}`" target="_blank">{{
                  absDocName }}</a></div>
            <div v-if="thesisInfo.secrecyDocUrl"><a
                :href="`${baseURL}/system/thesis/downloadFile?key=${thesisInfo.secrecyDocUrl}`" target="_blank">{{
                  secrecyDocName
                }}</a></div>
            <div v-if="thesisInfo.copyrightDocUrl"><a
                :href="`${baseURL}/system/thesis/downloadFile?key=${thesisInfo.copyrightDocUrl}`" target="_blank">{{
                  copyrightDocName }}</a></div>
          </template>
          <template v-else>
            <div>--</div>
          </template>
        </el-descriptions-item>
      </el-descriptions>

      <el-table :data="thesisInfo.authors" border style="width: 100%; margin-bottom: 20px;">
        <el-table-column type="index" label="作者顺序" width="80">
        </el-table-column>
        <el-table-column prop="authorName" label="姓名" width="180">
          <template slot-scope="{row}">
            {{ row.authorName + (row.isComm ? '（通讯作者）' : '') }}
          </template>
        </el-table-column>
        <el-table-column prop="authorUnit" label="单位">
        </el-table-column>
        <el-table-column prop="authorEmail" label="邮箱">
        </el-table-column>
        <el-table-column prop="authorPhone" label="联系方式">
        </el-table-column>
      </el-table>
    </div>
    <div class="page-footer">
      <Space>
        <el-button type="primary" style="width: 110px;" @click="handleSubmit">提交</el-button>
      </Space>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { baseURL } from '@/utils/request'
import { postSystemThesisUpsertBody } from '@/services/system'

const fileReg = /([^\/]+\.\w+$)/

export default {
  props: {
    current: Number
  },
  data() {
    return {
      baseURL,
      form: {
        bodyDocUrl: '',
        secrecyDocUrl: '',
        copyrightDocUrl: ''
      },
      rules: {
        bodyDocUrl: [{
          required: true,
          message: '请上传论文全文'
        }],
        secrecyDocUrl: [{ required: true, message: '请上传保密摘要' }],
        copyrightDocUrl: [{ required: true, message: '请上传版权协议' }]
      }
    }
  },
  computed: {
    ...mapState('user', {
      'access_token': state => state.token.access_token
    }),
    ...mapState('system', {
      'thesisInfo': state => state.thesisInfo
    }),
    absDocName() {
      return fileReg.test(this.thesisInfo.absDocUrl) ? decodeURIComponent(this.thesisInfo.absDocUrl.match(fileReg)[1]) : (this.thesisInfo.absDocUrl || '--')
    },
    secrecyDocName() {
      return fileReg.test(this.thesisInfo.secrecyDocUrl) ? decodeURIComponent(this.thesisInfo.secrecyDocUrl.match(fileReg)[1]) : (this.thesisInfo.secrecyDocUrl || '--')
    },
    copyrightDocName() {
      return fileReg.test(this.thesisInfo.copyrightDocUrl) ? decodeURIComponent(this.thesisInfo.copyrightDocUrl.match(fileReg)[1]) : (this.thesisInfo.copyrightDocUrl || '--')
    }
  },
  methods: {
    ...mapMutations('system', {
      setThesisInfo: 'setThesisInfo'
    }),
    async handleSubmit() {
      try {
        await this.$refs['form'].validate()

        const isOk = await this.$confirm('确定提交论文全文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })

        if (isOk) {
          await postSystemThesisUpsertBody({
            thesisId: this.thesisInfo.thesisId,
            bodyDocUrl: this.form.bodyDocUrl,
            secrecyDocUrl: this.form.secrecyDocUrl,
            copyrightDocUrl: this.form.copyrightDocUrl
          })

          this.setThesisInfo({
            ...this.thesisInfo,
            bodyDocUrl: this.form.bodyDocUrl,
            secrecyDocUrl: this.form.secrecyDocUrl,
            copyrightDocUrl: this.form.copyrightDocUrl
          })

          this.$message({
            type: 'success',
            message: '论文已提交，可在投稿状态页面查看论文状态。'
          });

          this.$router.push({
            path: '/user/submission/status'
          })

          this.$emit('onFinish')
        }
      } catch (e) {
        console.error(e)

        // this.$message.error('请上传附件')
      }
    },
    // 上传前
    handleBeforeUpload(file, maxSize) {
      if (file.size > maxSize) {
        this.$message.error('超过上传大小限制')

        return false
      }

      return true
    },
    // 上传成功
    handleUploadSuccess(response, fieldName) {
      const { code, data, msg } = response

      if (code === 200) {
        this.form[fieldName] = decodeURIComponent(data)
      } else if (code === 401) {
        this.$router.push({
          path: '/login'
        })
      } else {
        this.$message.error(msg)
      }
    }
  },
  watch: {
    thesisInfo: {
      handler(value) {
        this.form = Object.assign({}, this.form, value)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.page {
  &-header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  &-body {
    min-height: calc(100vh - 320px);
  }

  &-footer {
    display: flex;
    justify-content: center;
  }
}

.paper-tips {
  font-weight: 500;
  font-size: 18px;
  color: #E84B4C;
  line-height: 25px;
  padding-bottom: 38px;
}

::v-deep {
  .el-descriptions {
    a {
      color: $--cms-primary-color;
      text-decoration: none;

      &:hover {
        color: $--cms-link-hover-color;
      }
    }
  }

  .el-descriptions .el-descriptions-item__content {
    display: block;
  }

  .el-descriptions-item__label {
    font-weight: 600;
    width: 80px;
    justify-content: flex-end;
  }

  .el-descriptions .el-descriptions-item__cell {
    padding-bottom: 38px;
    line-height: 30px;
  }

  .el-form--label-left .el-form-item__label {
    text-align: right;
    font-weight: 600;
  }
}
</style>
